'use client';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

export interface ReadingListProps {
  children?: React.ReactNode;
  inlineStyle?: InlineStyles;
  styleExtend?: StyleExtend;
}

// `c` is short for `classNames`
const c = s9.create({
  btn: {
    color: 'var(--mHTxtClr)',
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    paddingInlineStart: space(3),
    paddingInlineEnd: space(3),
    lineHeight: space(7),

    ':hover': {
      '--mHTxtClr': color('neutral1100'),
    },
    ':focus': {
      '--mHTxtClr': color('neutral1100'),
    },
  },
  icon: {
    fontSize: space(5),
  },
});

export default function ReadingList() {
  const user = useUser('serverValue');

  const isPaying = user.userType === 'paying';

  if (!isPaying) return null;

  return (
    <Button
      variant="neutral"
      priority="tertiary"
      styleExtend={[c.btn]}
      as={HtzLink}
      href={'/account/reading-list'}
      data-testid="reading-list-btn"
      title={fork({
        default: 'רשימת קריאה',
        hdc: 'Reading list',
      })}
    >
      <Icon icon="bookmark" styleExtend={[c.icon]} />
      <VisuallyHidden>
        {fork({
          default: 'רשימת קריאה',
          hdc: 'Reading list',
        })}
      </VisuallyHidden>
    </Button>
  );
}
