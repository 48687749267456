'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import useAnimated, { useAnimatedUpdater } from '@haaretz/s-ui-providers/AnimatedProvider';
import * as React from 'react';
import s9 from 'style9';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  itemlist: {
    display: 'flex',
    minWidth: '100%',
    position: 'relative',

    ...border({
      color: color('neutral300'),
      side: 'bottom',
      style: 'solid',
      width: '4px',
      spacing: 0,
    }),
    borderBottomLeftRadius: radius('medium'),
    borderBottomRightRadius: radius('medium'),
  },
  activeIndicator: {
    backgroundColor: color('primary1000'),
    top: space(-0.25),
    height: space(1),
    insetInlineStart: 'var(--indicator-start)',
    position: 'absolute',
    transitionDuration: '500ms',
    transitionProperty: 'all',
    transitionTimingFunction: 'ease-in-out',
    width: 'var(--indicator-width)',
    zIndex: zIndex('above'),
    borderBottomLeftRadius: radius('large'),
    borderBottomRightRadius: radius('large'),
  },
  animation: {
    width: 'var(--indictor-animation-width)',
  },
});

interface ItemListProps {
  children: React.ReactNode;
  styleExtend?: StyleExtend;
  inlineStyle?: InlineStyles;
  removeStyle?: boolean;
}

const sideBySite = fork<'left' | 'right'>({ default: 'right', hdc: 'left' });

function ItemList({ children, styleExtend = [], inlineStyle, removeStyle }: ItemListProps) {
  const { activeIndicatorRect, rect } = useAnimated();

  const [isAnimation, setIsAnimation] = React.useState(!!activeIndicatorRect);

  React.useEffect(() => {
    if (!isAnimation) {
      setIsAnimation(true);
    }
  }, [isAnimation, activeIndicatorRect]);

  const { setRect } = useAnimatedUpdater();

  const listRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const currentRef = listRef.current;

    function updateRect() {
      if (currentRef) {
        const currentRect = currentRef.getBoundingClientRect();
        setRect(currentRect);
      }
    }
    updateRect();

    const resizeObserver = new ResizeObserver(updateRect);

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    window.addEventListener('resize', updateRect);

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
      window.removeEventListener('resize', updateRect);
    };
  }, [setRect]);
  const style = {
    '--indicator-start': `${
      activeIndicatorRect && rect ? rect[sideBySite] - activeIndicatorRect[sideBySite] : 0
    }px`,

    '--indicator-width': `${activeIndicatorRect ? activeIndicatorRect.width : 0}px`,
    '--indictor-animation-width': `${
      activeIndicatorRect && rect ? activeIndicatorRect.width : 0
    }px`,
  } as React.CSSProperties;

  return (
    <div ref={listRef} className={s9(c.itemlist, ...styleExtend)} style={inlineStyle}>
      {children}
      <div
        className={s9(removeStyle ? null : c.activeIndicator, isAnimation && c.animation)}
        onTransitionEnd={() => setIsAnimation(false)}
        style={style}
      />
    </div>
  );
}

export default ItemList;
