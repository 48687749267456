'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import { useAnimationReducedAtom } from '@haaretz/s-atoms/animationReduced';
import { useContrastModeAtom } from '@haaretz/s-atoms/contrastMode';
import Button from '@haaretz/s-button';
import Dialog from '@haaretz/s-dialog';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { ModalProps } from '@haaretz/s-modal';
export interface A11yMenuProps {
  isOpen: boolean;
  onClick?: () => void;
  onToggle?: ModalProps['onToggle'];
}

// `c` is short for `classNames`
const c = s9.create({
  icon: {
    fontSize: space(5),
  },
  btn: {
    color: 'var(--mHTxtClr)',
    border: 'none',
    lineHeight: space(7),
    paddingBottom: 0,
    paddingInlineEnd: space(3),
    paddingInlineStart: space(3),
    paddingTop: 0,

    ':hover': {
      '--mHTxtClr': color('neutral1100'),
    },
    ':focus': {
      '--mHTxtClr': color('neutral1100'),
    },
  },
  btnWhenOpen: {
    color: color('primary1000'),
    pointerEvents: 'none',
  },
  dialog: {
    position: 'absolute',
    top: space(13),
    marginInlineEnd: 0,
    padding: 0,
    border: `1px solid ${color('neutral300')}`,
  },
  backdropDialog: {
    zIndex: 0,
  },
  li: {
    display: 'flex',
    height: space(10),
  },
  btnInsideLi: {
    width: '100%',
    textAlign: 'start',
    paddingInlineStart: space(2),
    fontWeight: 400,
    border: 'none',
    backgroundColor: color('contentBg'),
    color: color('bodyText'),
    ':hover': {
      backgroundColor: color('neutral200'),
      color: color('primary1000'),
    },
    ':focus': {
      backgroundColor: color('neutral200'),
      color: color('primary1000'),
      outline: 'none',
    },
  },
  btnText: {
    ...typesetter(-1),
    ...merge({
      ...mq({ from: 'xl', value: { ...typesetter(-2) } }),
    }),
  },
});

export default function A11yMenuWrapper() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Button
        variant="neutral"
        priority="tertiary"
        styleExtend={[c.btn, isOpen && c.btnWhenOpen]}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="a11y-menu-btn"
        title={fork({ default: 'תפריט נגישות', hdc: 'accessibility' })}
      >
        <Icon icon="a11y" styleExtend={[c.icon]} />
        <VisuallyHidden>{fork({ default: 'תפריט נגישות', hdc: 'accessibility' })}</VisuallyHidden>
      </Button>
      <A11yMenu onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />
    </React.Fragment>
  );
}

function A11yMenu({ isOpen, onClick }: A11yMenuProps) {
  const dialogRef = React.useRef<HTMLDialogElement>(null);

  const [animationReduced, setAnimationReduced] = useAnimationReducedAtom();
  const [isContrastMode, setIsContrastMode] = useContrastModeAtom();

  const A11yMenuItems = [
    {
      title: `${isContrastMode ? 'עצירת' : 'הפעלת'} מצב ניגודיות `,
      onClick: () => setIsContrastMode(!isContrastMode),
    },
    {
      title: `${animationReduced ? 'הפעלת' : 'הפסקת'} אנימציה `,
      onClick: () => setAnimationReduced(!animationReduced),
    },
    {
      title: 'דיווח על בעיית נגישות',
      href: 'mailto:accessibility@haaretz.co.il',
    },
    {
      title: 'הצהרת נגישות',
      href: '/misc/accessibility',
    },
    fork({
      default: {
        title: 'כתבות להאזנה',
        href: '/digital/podcast/ontheway',
      },
      tm: null,
    }),
  ];

  // note: this is to keep the focus inside the dialog.
  React.useEffect(() => {
    const focusableElements = dialogRef.current
      ? (Array.from(
          dialogRef.current.querySelectorAll(
            'a[href], button:not([disabled]),[tabindex]:not([tabindex="-1"])'
          )
        ) as HTMLElement[])
      : [];

    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];
    const handleKeyDown = (e: KeyboardEvent) => {
      // Check for TAB key press
      if (e.key === 'Tab') {
        // SHIFT + TAB
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus();
            e.preventDefault();
          }
        }
        // TAB
        else {
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            e.preventDefault();
          }
        }
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClick}
      backdropStyleExtend={[c.backdropDialog]}
      styleExtend={[c.dialog]}
      ref={dialogRef}
      data-testid="a11y-menu"
      closeOnAllOutsideClicks
    >
      <ul>
        {A11yMenuItems.map(item => {
          if (!item) return null;
          return (
            <li key={item.title} className={s9(c.li)}>
              {item.href ? (
                <Button
                  priority="secondary"
                  variant="neutral"
                  styleExtend={[c.btnInsideLi]}
                  contentStyleExtend={[c.btnText]}
                  as={HtzLink}
                  href={item.href}
                >
                  {item.title}
                </Button>
              ) : (
                <Button
                  priority="secondary"
                  variant="neutral"
                  styleExtend={[c.btnInsideLi]}
                  contentStyleExtend={[c.btnText]}
                  as="button"
                  onClick={() => {
                    item.onClick && item.onClick();
                    onClick && onClick();
                  }}
                >
                  {item.title}
                </Button>
              )}
            </li>
          );
        })}
      </ul>
    </Dialog>
  );
}
