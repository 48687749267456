'use client';

import { AnimatedProvider } from '@haaretz/s-ui-providers/AnimatedProvider';
import {
  DescendantProvider,
  createDescendantContext,
  type Descendant,
} from '@haaretz/s-ui-providers/DescendantsProvider';
import * as React from 'react';

import { ItemsProvider } from './components/providers/ItemsProvider';

/* ItemsProps accepts an array of HTML button elements with the role of `Item`, and, optionally,
the index of the Item selected by default */
interface ItemsProps {
  children: React.ReactNode;
}

export const ItemsDescendantContext =
  createDescendantContext<Descendant<HTMLButtonElement>>('ItemsDescendantContext');

export default function useItemsDescendant() {
  const context = React.useContext(ItemsDescendantContext);

  if (!context) {
    throw new Error('useItems must be used within a ItemsContext');
  }

  return context;
}

/* We create ItemsDescendantContext to keep track of the created Items.
   To do so, we register every Item inside the Item component, adding it to the descendants array.
   Each Item gets index based on it's position in the DOM,
   which, in turn, is based on the order of the declaration of the Items
*/

export function NavigationItems({ children }: ItemsProps) {
  const [items, setItems] = React.useState<Array<Descendant<HTMLButtonElement>>>([]);

  return (
    <DescendantProvider context={ItemsDescendantContext} items={items} set={setItems}>
      <ItemsProvider>
        <AnimatedProvider>{children}</AnimatedProvider>
      </ItemsProvider>
    </DescendantProvider>
  );
}

export function makeId(...args: Array<string | number | null | undefined>) {
  return args.filter(val => val != null).join('--');
}
