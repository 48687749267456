'use client';
import space from '@haaretz/l-space.macro';
import ErrorBoundary from '@haaretz/s-error-boundary';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import s9 from 'style9';

import type { NavigationEntryFragment } from '@haaretz/s-fragments/NavigationEntry';

interface SectionNavigationLinkProps
  extends NonNullable<NavigationEntryFragment['subEntries'][number]> {}

const c = s9.create({
  htzLink: {
    fontWeight: 700,
    paddingInlineStart: space(1),
  },
});

function SectionNavigationLink(item: SectionNavigationLinkProps) {
  const biAction = useBi();

  if (!item || !item.title || !item.href) return null;

  return (
    <HtzLink
      className={s9(c.htzLink)}
      href={item?.href}
      onClick={() => {
        biAction({
          feature: 'Top navigation',
          feature_type: 'Content',
          action_id: 133,
          campaign_details: item.title,
        });
      }}
    >
      {item.title}
    </HtzLink>
  );
}

export default function SectionNavigationLinkWrapper(item: SectionNavigationLinkProps) {
  return (
    <ErrorBoundary>
      <SectionNavigationLink {...item} />
    </ErrorBoundary>
  );
}
