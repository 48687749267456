'use client';

import * as React from 'react';

interface ItemsContextValue {
  selectedIndex?: number | null;
  rootId: string;
  activeItemRef: HTMLButtonElement | null;
}

const defaultItemsContextValue: ItemsContextValue = {
  selectedIndex: -1,
  rootId: '',
  activeItemRef: null,
};

type ItemsProviderActionsType = {
  onSelectItem: React.Dispatch<React.SetStateAction<number | null>>;
  setActiveItemRef: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
};

export const ItemsActionsContext = React.createContext<ItemsProviderActionsType | undefined>(
  undefined
);

export const ItemsContext = React.createContext<ItemsContextValue>(defaultItemsContextValue);

export default function useItems() {
  const context = React.useContext(ItemsContext);

  if (!context) {
    throw new Error('useItems must be used within a ItemsContext');
  }

  return context;
}

export function useItemsActions() {
  const context = React.useContext(ItemsActionsContext);

  if (!context) {
    throw new Error('useItemsActions must be used within a ItemsContext');
  }

  return context;
}

export function ItemsProvider({
  children,
  defaultItemIndex,
}: {
  children: React.ReactNode;
  defaultItemIndex?: number;
}) {
  const [selectedIndex, setSelectedIndex] = React.useState<null | number>(defaultItemIndex || null);
  const [activeItemRef, setActiveItemRef] = React.useState<HTMLButtonElement | null>(null);
  const rootId = React.useId();

  const onSelectItem = React.useCallback(
    (index: React.SetStateAction<number | null>) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex]
  );

  const itemsActionsValue = React.useMemo(
    () => ({ onSelectItem, setSelectedIndex, setActiveItemRef }),
    [onSelectItem, setSelectedIndex, setActiveItemRef]
  );

  const itemsContextValue = React.useMemo(
    () => ({
      rootId,
      activeItemRef,
      selectedIndex,
    }),
    [rootId, activeItemRef, selectedIndex]
  );

  return (
    <ItemsContext.Provider value={itemsContextValue}>
      <ItemsActionsContext.Provider value={itemsActionsValue}>
        {children}
      </ItemsActionsContext.Provider>
    </ItemsContext.Provider>
  );
}
