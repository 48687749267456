'use client';

import space from '@haaretz/l-space.macro';
import useUser from '@haaretz/s-atoms/user';
import * as React from 'react';
import s9 from 'style9';

import greetUser from '../utils/getGreetingAccordingTime';

import LoginAndLogout from './LoginAndLogout';
import PurchaseButton from './PurchaseButton';

import type { StyleExtend } from '@haaretz/s-types';

export interface MobileHeaderProps {
  styleExtend?: StyleExtend;
}

// `c` is short for `classNames`
const c = s9.create({
  mobileGreetingTxt: {
    fontSize: space(6),
    marginTop: space(6),
  },
  firstName: {
    fontWeight: 700,
  },
  purchaseAndLoginWrapper: {
    display: 'flex',
    marginTop: space(4),
    marginInlineStart: `-${space(4)}`,
    gap: space(1),
  },
  purchaseBtn: {
    marginInlineStart: `${space(2)}`,
  },
});

export default function MobileHeader({ styleExtend = [] }: MobileHeaderProps) {
  const user = useUser('cookieValue');

  return (
    <React.Fragment>
      <div className={s9(c.mobileGreetingTxt, ...styleExtend)}>
        {greetUser()}
        {user.isLoggedIn && user.firstName ? (
          <span className={s9(c.firstName)}>{user.firstName}</span>
        ) : null}
      </div>
      <div className={s9(c.purchaseAndLoginWrapper)}>
        <PurchaseButton styleExtend={[c.purchaseBtn]} />
        <LoginAndLogout />
      </div>
    </React.Fragment>
  );
}
