import * as Types from '@haaretz/s-fragments/Types';

export type UserCredentialsTypeFragment = (
  { success: boolean, code: number, messages?: Array<string> | null, status?: string | null, user?: (
    { abuse: (
      { redirect?: string | null }
      & { __typename: 'CredentialsUserAbuseType' }
    ) }
    & { __typename: 'CredentialsUserType' }
  ) | null }
  & { __typename: 'UserCredentialsType' }
);


export type UserCredentialsTypeFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const UserCredentialsTypeFragmentGql = `
    fragment UserCredentialsTypeFragment on UserCredentialsType {
  __typename
  success
  code
  messages
  user {
    __typename
    abuse {
      __typename
      redirect
    }
  }
  status
}
    `;