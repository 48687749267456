'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import ClickArea from '@haaretz/s-click-area';
import HtzLink from '@haaretz/s-htz-link';
import { useAnimatedUpdater } from '@haaretz/s-ui-providers/AnimatedProvider';
import { Descendant, useDescendant } from '@haaretz/s-ui-providers/DescendantsProvider';
import * as React from 'react';
import s9 from 'style9';

import { ItemsDescendantContext } from '../Items';

import useItems, { useItemsActions } from './providers/ItemsProvider';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  Item: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'inline-flex',
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
    minWidth: 0,
    marginTop: space(-1.5),
  },
  ItemActive: {
    color: color('primary1000'),
    fontWeight: '700',
  },
});

interface ItemProps {
  children: React.ReactNode;
  hasMenu?: boolean;
  kind?: 'default' | 'mainMenu';
  styleExtend?: StyleExtend;
  inlineStyle?: InlineStyles;
  onClick?: () => void;
  href?: string | null;
  currentSelection?: string | null;
  onKeyDownCapture?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function Item({
  children,
  styleExtend = [],
  inlineStyle,
  onClick,
  kind,
  href,
  currentSelection,
  onKeyDownCapture,
}: ItemProps) {
  const { selectedIndex } = useItems();
  const { onSelectItem, setActiveItemRef } = useItemsActions();

  const { setActiveIndicatorRect } = useAnimatedUpdater();
  const { descendants } = React.useContext(ItemsDescendantContext);

  const [isSelected, setIsSelect] = React.useState(false);

  const ItemRef = React.useRef<HTMLButtonElement>(null);

  const index = useDescendant(ItemRef.current, ItemsDescendantContext);

  React.useEffect(() => {
    if (selectedIndex === index && currentSelection) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [index, currentSelection, selectedIndex]);

  function onSelect() {
    onClick && onClick();
    onSelectItem(index);
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      const offset =
        event.key === fork<'ArrowLeft' | 'ArrowRight'>({ default: 'ArrowLeft', hdc: 'ArrowRight' })
          ? 1
          : -1;
      const nextIndexToFocus = calculateNextIndexToFocus(descendants, index, offset);
      nextIndexToFocus && descendants[nextIndexToFocus]?.element?.focus();
      onSelectItem(nextIndexToFocus);
    }
    const inputEvent = event as React.KeyboardEvent<HTMLInputElement>;
    onKeyDownCapture && onKeyDownCapture(inputEvent);
  }

  React.useEffect(() => {
    const currentItemRef = ItemRef.current;

    if (isSelected && currentItemRef) {
      setActiveItemRef(currentItemRef);
      setActiveIndicatorRect(currentItemRef.getBoundingClientRect());
    }

    function updateIndicatorRect() {
      if (isSelected && currentItemRef) {
        setActiveIndicatorRect(currentItemRef.getBoundingClientRect());
      }
    }

    const resizeObserverItem = new ResizeObserver(updateIndicatorRect);

    if (currentItemRef) {
      resizeObserverItem.observe(currentItemRef);
    }

    window.addEventListener('resize', updateIndicatorRect);

    return () => {
      if (currentItemRef) {
        resizeObserverItem.unobserve(currentItemRef);
      }
      window.removeEventListener('resize', updateIndicatorRect);
    };
  }, [isSelected, setActiveItemRef, setActiveIndicatorRect]);

  if (kind === 'mainMenu') {
    return (
      <button
        className={s9(c.Item, ...styleExtend)}
        style={inlineStyle}
        ref={ItemRef}
        onClick={onSelect}
        onKeyDown={onKeyDown}
      >
        {children}
      </button>
    );
  }
  const sharedProps = {
    styleExtend: [c.Item, isSelected && c.ItemActive, ...styleExtend],
    onClick: onSelect,
    style: inlineStyle,
    onKeyDown,
  };

  if (href) {
    return (
      <ClickArea
        {...sharedProps}
        ref={ItemRef as unknown as React.RefObject<HTMLAnchorElement>}
        as={HtzLink}
        href={href}
      >
        {children}
      </ClickArea>
    );
  }

  return (
    <ClickArea {...sharedProps} ref={ItemRef}>
      {children}
    </ClickArea>
  );
}
function calculateNextIndexToFocus(
  descendants: Array<Descendant<HTMLButtonElement>>,
  currentIndex: number,
  offset: number
) {
  let index =
    (fork({
      default: currentIndex + offset,
      hdc: currentIndex - offset + descendants.length,
    }) +
      descendants.length) %
    descendants.length;

  while (descendants[index]?.element?.disabled) {
    index =
      (fork({
        default: index + offset,
        hdc: index - offset + descendants.length,
      }) +
        descendants.length) %
      descendants.length;
  }

  return index;
}
