'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Button from '@haaretz/s-button';
import Dialog from '@haaretz/s-dialog';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import getBiDataFromMastheadItem from '@haaretz/s-use-bi/getBiDataFromMastheadItem';
import * as React from 'react';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { NavigationEntryFragment } from '@haaretz/s-fragments/NavigationEntry';

export interface DesktopNavigationMenuProps {
  mainNavigation: NavigationEntryFragment['subEntries'];
  isOpen: boolean;
  onClick?: () => void;
}

// `c` is short for `classNames`
const c = s9.create({
  btn: {
    height: '100%',
    display: 'flex',
    width: space(5),
    gridColumnStart: 1,
    gridColumnEnd: 2,
    zIndex: zIndex('above'),
    ...merge(
      mq({
        from: 'm',
        value: {
          display: 'inline',
          width: space(18),
          padding: 0,
        },
      })
    ),
  },
  text: {
    display: 'none',
    ...merge(
      mq({
        from: 'm',
        value: {
          ...typesetter(-2),
          display: 'inline',
          minWidth: space(12),
          marginRight: space(4),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
  isOpen: {
    color: color('primary1000'),
  },
  btnContent: {
    display: 'flex',
    alignItems: 'center',
    ...merge(
      mq({
        from: 'm',
        value: {
          marginInlineStart: space(2),
        },
      })
    ),
  },
  desktopDialog: {
    color: color('bodyText'),
    border: `1px solid ${color('neutral300')}`,
    marginInlineStart: 0,
    padding: 0,
    top: space(13),
  },
  backdropDialog: {
    zIndex: 0,
  },
  li: {
    display: 'flex',
    width: space(52),
    height: space(10),
    paddingInlineStart: space(2),
    ...typesetter(-1),
    ...merge({
      ...mq({ from: 'xl', value: { width: space(52), height: space(9), ...typesetter(-2) } }),
    }),
    ':hover': {
      backgroundColor: color('neutral200'),
      color: color('primary1000'),
    },
  },
  commercialLi: {
    backgroundColor: color('neutral300'),
    color: color('promoted300'),
    fontWeight: 700,
    ':hover': {
      backgroundColor: color('neutral400'),
      color: color('promoted300'),
      fontWeight: 700,
    },
  },
  commercialLiSub: {
    backgroundColor: color('neutral300'),
    color: color('promoted300'),
    fontWeight: 700,
    ':hover': {
      backgroundColor: color('neutral400'),
      color: color('promoted300'),
      fontWeight: 700,
    },
    borderBottomColor: color('neutral200'),
    borderBottom: '1px solid',
  },
  site: {
    borderBottomColor: color('primary500'),
    borderBottom: '1px solid',
  },
  btnIconArrow: {
    border: 'none',
    height: '100%',
    color: 'inherit',
    ':hover': {
      color: color('primary1000'),
      backgroundColor: 'none',
    },
    ':active': {
      color: color('primary1000'),
      backgroundColor: 'none',
    },
    ':focus-within': {
      color: color('primary1000'),
      backgroundColor: 'none',
    },
    ':focus': {
      color: color('primary1000'),
      backgroundColor: 'none',
    },
  },
  link: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chevron: {
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
    transform: 'rotate(-180deg)',
  },
  rotate: {
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
    transform: 'rotate(0deg)',
  },
  subNavigation: {
    position: 'absolute',
    backgroundColor: color('neutral200'),
    marginRight: space(52),
    top: `-${space(0.25)}`,
    height: `calc(100% + ${space(0.5)})`,
    ...merge({
      ...mq({ from: 's', value: { border: `1px solid ${color('neutral300')}` } }),
      ...mq({
        from: 'xl',
        value: { width: `${space(52)} -1px`, marginRight: space(52), ...typesetter(-2) },
      }),
    }),
  },
  activeLi: {
    backgroundColor: color('neutral200'),
    color: color('primary1000'),
  },
});

export default function DesktopNavigation({
  mainNavigation,
  isOpen,
  onClick,
}: DesktopNavigationMenuProps) {
  const [subItemsExpanded, setSubItemsExpand] = React.useState<null | number>(null);
  const [focusItem, setFocusItem] = React.useState<null | number>(null);
  const [focusSubItem, setFocusSubItem] = React.useState<null | number>(null);

  const ulRef = React.useRef<HTMLUListElement | null>(null);
  const subUlRef = React.useRef<HTMLUListElement | null>(null);

  const keyboardEscape = (e: React.KeyboardEvent<HTMLUListElement>) => {
    if (['Escape'].includes(e.key) && subItemsExpanded !== null && focusItem !== null) {
      e.stopPropagation();
      e.preventDefault();

      const li =
        (ulRef.current?.querySelectorAll(':scope > li')?.[focusItem] as HTMLLIElement) || null;

      li?.querySelector('button')?.focus();
      setSubItemsExpand(null);
    }
  };
  const biAction = useBi();
  //  note: this useEffect is just for subUlLi,
  // if it׳s expanded so focus the first li, if it close so focus on the arrow of the focusItem that own the subLi.
  //  we want it to happen just when the subNavigation open and close.
  React.useEffect(() => {
    if (subUlRef.current && subItemsExpanded !== null) {
      const firstLi = (subUlRef.current?.firstChild?.firstChild as HTMLElement) || null;
      firstLi && firstLi.focus();
    } else {
      setFocusSubItem(null);
      if (focusItem) {
        const li =
          (ulRef.current?.querySelectorAll(':scope > li')?.[focusItem] as HTMLLIElement) || null;
        li?.querySelector('button')?.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subItemsExpanded]);

  // note: this is to keep the focus inside the dialog.
  React.useEffect(() => {
    const focusableElements = ulRef.current
      ? (Array.from(
          ulRef.current.querySelectorAll(
            'a[href], button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])'
          )
        ) as HTMLElement[])
      : [];

    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            e.preventDefault();
          }
        }
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const onBlur = (
    ul: React.RefObject<Element>,
    entries: NavigationEntryFragment['subEntries'][number]['subEntries'],
    id: number,
    evt: React.FocusEvent
  ) => {
    const nextElTitle =
      (evt?.relatedTarget as HTMLElement)?.dataset?.title ||
      (evt?.relatedTarget as HTMLElement)?.textContent ||
      '';

    const entriesTitle = entries?.map((entry: { title: string }) => entry.title);

    const firstSubLi = ul.current?.firstChild?.firstChild as HTMLElement;
    const lastSubLi = ul?.current?.lastChild?.firstChild as HTMLElement;

    if (!entriesTitle.includes(nextElTitle)) {
      if (ul.current) {
        if (id === 0) {
          lastSubLi && lastSubLi.focus();
        } else {
          firstSubLi && firstSubLi.focus();
        }
      }
    }
    return null;
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClick}
      styleExtend={[c.desktopDialog]}
      backdropStyleExtend={[c.backdropDialog]}
      data-testid="navigation-menu"
      closeOnAllOutsideClicks
    >
      <ul onKeyDownCapture={keyboardEscape} ref={ulRef}>
        {mainNavigation?.map((entry, i) => {
          const { title, href, kind } = entry || {};
          const hasSubItems = entry?.subEntries.length > 0 || false;
          const isCommercialEntry = kind === 'commercial';
          const biData = getBiDataFromMastheadItem(title, isCommercialEntry);

          return (
            <React.Fragment key={title}>
              <NavigationLiItem
                title={title}
                href={href}
                hasSubItems={hasSubItems}
                setSubItemsExpand={setSubItemsExpand}
                subItemsExpanded={subItemsExpanded}
                focusItem={focusItem}
                setFocusItem={setFocusItem}
                kind={kind}
                idx={i}
                mainNavigationLength={mainNavigation.length}
                isCommercial={isCommercialEntry}
                onClick={() => {
                  biAction(biData);
                }}
              />

              {/* //subitems navigation */}
              {hasSubItems && subItemsExpanded === i && (
                <ul
                  className={s9(c.subNavigation)}
                  onMouseEnter={() => setSubItemsExpand(i)}
                  onMouseLeave={() => setSubItemsExpand(null)}
                  data-testid="sub-main-navigation"
                  ref={subUlRef}
                >
                  {entry.subEntries?.map((item, id) => {
                    const ItemSubEntries = entry.subEntries;
                    const isCommercialSubEntry = item.kind === 'commercial';
                    const biDataSubEntry = getBiDataFromMastheadItem(
                      item.title,
                      isCommercialSubEntry
                    );
                    if (!item || !item.title || !item.href) return null;
                    return (
                      <li
                        key={item.href}
                        className={s9(
                          c.li,
                          focusSubItem === id && c.activeLi,
                          isCommercialSubEntry && c.commercialLiSub
                        )}
                        onKeyUpCapture={() => setFocusSubItem(id)}
                        onBlur={event => {
                          onBlur(subUlRef, ItemSubEntries, id, event);
                          return null;
                        }}
                        tabIndex={-1}
                      >
                        <HtzLink
                          className={s9(c.link)}
                          href={item.href}
                          onClick={() => {
                            biAction(biDataSubEntry);
                          }}
                        >
                          {item.title}
                        </HtzLink>
                      </li>
                    );
                  })}
                </ul>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </Dialog>
  );
}

export interface NavigationLiItemProps {
  title: string;
  href?: string | null;
  hasSubItems?: boolean;
  focusItem?: number | null;
  idx: number;
  kind?: string | null;
  subItemsExpanded: number | null;
  setSubItemsExpand: React.Dispatch<React.SetStateAction<number | null>>;
  setFocusItem: React.Dispatch<React.SetStateAction<number | null>>;
  mainNavigationLength: number;
  biData?: BiDataOverrides;
  isCommercial?: boolean;
  onClick?: () => void;
}

function NavigationLiItem({
  title,
  href,
  hasSubItems,
  setSubItemsExpand,
  subItemsExpanded,
  setFocusItem,
  focusItem,
  idx,
  kind,
  isCommercial,
  onClick,
}: NavigationLiItemProps) {
  if (!title || !href) return null;

  const isSite = kind === 'site';

  return (
    <li
      className={s9(
        c.li,
        focusItem === idx && c.activeLi,
        subItemsExpanded === idx ? c.activeLi : null,
        isCommercial && c.commercialLi
      )}
      onMouseEnter={() => {
        hasSubItems && setSubItemsExpand && setSubItemsExpand(idx);
      }}
      onMouseLeave={() => {
        hasSubItems && setSubItemsExpand && setSubItemsExpand(null);
        setFocusItem && setFocusItem(null);
      }}
      onFocus={() => setFocusItem && setFocusItem(idx)}
      tabIndex={-1}
    >
      {href && (
        <HtzLink className={s9(c.link)} href={href} onClick={onClick}>
          <span className={s9(isSite && !isCommercial && c.site)}>{title}</span>
        </HtzLink>
      )}
      {hasSubItems && (
        <Button
          type="button"
          priority="secondary"
          variant="neutral"
          styleExtend={[c.btnIconArrow]}
          data-title={title}
          onClick={() => {
            if (subItemsExpanded === idx) {
              setSubItemsExpand && setSubItemsExpand(null);
              setFocusItem && setFocusItem(null);
            } else {
              setSubItemsExpand && setSubItemsExpand(idx);
              setFocusItem && setFocusItem(idx);
            }
          }}
        >
          <Icon icon="chevron" styleExtend={[c.chevron, subItemsExpanded === idx && c.rotate]} />
        </Button>
      )}
    </li>
  );
}
