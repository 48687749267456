import * as Types from '@haaretz/s-fragments/Types';

import { UserCredentialsTypeFragmentGql } from '@haaretz/s-fragments/UserCredentialsType';
import { UserCredentialsTypeFragment } from '@haaretz/s-fragments/UserCredentialsType';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type LogoutMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.LogoutInputType>;
}>;


export type LogoutMutation = (
  { logout: (
    UserCredentialsTypeFragment
    & { __typename: 'UserCredentialsType' }
  ) }
  & { __typename: 'Mutation' }
);


export const LogoutMutationDocument = `
    mutation LogoutMutation($input: LogoutInputType) {
  logout(input: $input) {
    ...UserCredentialsTypeFragment
  }
}
    ${UserCredentialsTypeFragmentGql}`;
export const useLogoutMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>) =>
    useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
      ['LogoutMutation'],
      (variables?: LogoutMutationVariables) => fetch<LogoutMutation, LogoutMutationVariables>(LogoutMutationDocument, variables)(),
      options
    );
useLogoutMutation.getKey = () => ['LogoutMutation'];

useLogoutMutation.fetcher = (variables?: LogoutMutationVariables, options?: RequestInit['headers']) => fetch<LogoutMutation, LogoutMutationVariables>(LogoutMutationDocument, variables, options);