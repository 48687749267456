import { useSetCookie } from '@haaretz/s-atoms/cookie';
import { useSetUser } from '@haaretz/s-atoms/user';
import { useZenModeSetter } from '@haaretz/s-atoms/zenMode';
import { useLogoutMutation } from '@haaretz/s-lgn-mutations/Logout';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import type { LogoutMutation, LogoutMutationVariables } from '@haaretz/s-lgn-mutations/Logout';

export default function useLogout<TError = Error, TContext = unknown>(
  options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>
) {
  const client = useQueryClient();
  const updateUser = useSetUser();
  const updateZenMode = useZenModeSetter();
  const updateCookie = useSetCookie();

  const logout = useLogoutMutation({
    ...options,

    onSuccess: async (data, variables, context) => {
      if (data.logout.success) {
        updateUser('logout', () => client.clear());

        updateCookie();
        updateZenMode(false);
      }

      // istanbul ignore else
      if (typeof options?.onSuccess === 'function') {
        options.onSuccess(data, variables, context);
      }
    },
  });

  return logout;
}
