import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend } from '@haaretz/s-types';

export interface ColorModeProps {
  styleExtend?: StyleExtend;
}

// `c` is short for `classNames`
const c = s9.create({
  controlsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: space(2),
    borderRadius: radius('large'),
    padding: space(4),
    marginInlineStart: `-${space(2)}`,
    backgroundColor: color('neutral100', { opacity: 0.5 }),
    marginTop: space(2),
    ...merge({
      ...mq({
        from: 'l',
        value: {
          marginTop: space(10),
        },
      }),
    }),
  },
});

interface UserMenuWrapperProps {
  styleExtend?: StyleExtend;
}

export default function ControllsWrapper({
  children,
  styleExtend = [],
}: React.PropsWithChildren<UserMenuWrapperProps>) {
  return <div className={s9(c.controlsWrapper, ...styleExtend)}>{children}</div>;
}
