import browserStorageGetItem from '@haaretz/s-browser-storage-utils/getItem';
import browserStorageSetItem from '@haaretz/s-browser-storage-utils/setItem';
import { useSearchParams } from 'next/navigation';

import type { Mode } from '@haaretz/s-types';

export default function setAndPersistColorMode(mode: string | null, persist = true) {
  if (isMode(mode)) {
    try {
      if (persist) {
        mode && browserStorageSetItem({ key: 'htzColorMode', storage: 'local', value: mode });
      }
      let colorModeToSet: Mode;
      if (mode === 'system') {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) colorModeToSet = 'dark';
        else colorModeToSet = 'light';
      } else colorModeToSet = mode;

      if (colorModeToSet === 'light') {
        document.documentElement.classList.remove('darkMode');
      } else {
        document.documentElement.classList.add('darkMode');
      }
      document.documentElement.dataset.theme = colorModeToSet;
    } catch (err) {
      console.error('Failed to set color mode:\n', (err as Error).message);
    }
  } else console.error(`Invalid color mode: ${mode}`);
}
/**
 * Returns the active color mode.
 */
export function useColorMode() {
  const DARK_MODE: Mode = 'dark';
  const searchParams = useSearchParams();
  const isDarkModeFromParams = searchParams.get('darkMode') === 'true';
  if (isDarkModeFromParams) return { colorMode: DARK_MODE };

  try {
    const colorMode = browserStorageGetItem({ key: 'htzColorMode', storage: 'local' });
    if (colorMode && isMode(colorMode)) return { colorMode };
  } catch (err) {
    console.error('Failed to retrieve color mode:\n', (err as Error).message);
  }
  return undefined;
}

const MODES = ['dark', 'light', 'system'];
function isMode(candidate: string | null): candidate is Mode {
  return !!candidate && MODES.includes(candidate);
}
