'use client';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import { hostname } from '@haaretz/s-consts';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import { usePathname } from 'next/navigation';
import React from 'react';
import s9 from 'style9';

import getUserSubscriptionDetails from '../utils/getUserSubscriptionDetails';

import type { StyleExtend } from '@haaretz/s-types';

export interface PurchaseButtonProps {
  styleExtend?: StyleExtend;
}

const c = s9.create({
  textColor: {
    color: color('mastheadTextColor'),
  },
});

export default function PurchaseButton({ styleExtend = [] }: PurchaseButtonProps) {
  const biImpression = useBi('impression');
  const [currentPathname, setCurrentPathname] = React.useState<string>('');
  const biAction = useBi();
  const pathname = usePathname();
  const user = useUser('cookieValue');
  const { isPartialSubscriber, showPurchaseBtn } = getUserSubscriptionDetails(user);

  React.useEffect(() => {
    setCurrentPathname(pathname);
  }, [pathname]);

  React.useEffect(() => {
    const isSamePathname = pathname === currentPathname;
    if (!showPurchaseBtn || isSamePathname) return;
    biImpression({
      feature_type: 'Marketing',
      feature: 'Hello Subscription',
      campaign_name: isPartialSubscriber ? 'upsell' : 'subscription',
      campaign_details: 'hello_default',
    });
  }, [biImpression, currentPathname, isPartialSubscriber, pathname, showPurchaseBtn]);

  if (!showPurchaseBtn) return null;

  const onClick = () => {
    biAction({
      campaign_details: 'hello_default',
      campaign_name: isPartialSubscriber ? 'upsell' : 'subscription',
      feature: 'Hello Subscription',
      feature_type: 'Marketing',
      action_id: 3,
    });
  };

  return (
    <Button
      variant="sales"
      priority="primary"
      contentStyleExtend={[c.textColor]}
      styleExtend={styleExtend}
      as={HtzLink}
      onClick={onClick}
      href={`https://promotion.${hostname}/offers?htm_source=site&htm_medium=button&htm_campaign=subscription&htm_campaign_type=${
        isPartialSubscriber ? 'upsell' : 'subscription'
      }&htm_content=hello_default`}
      data-testid="purchase-btn"
    >
      {fork({
        default: isPartialSubscriber ? 'שדרוג מינוי' : 'לרכישת מינוי',
        hdc: 'Subscribe',
      })}
    </Button>
  );
}
