import fork from '@haaretz/l-fork.macro';
import { hostname } from '@haaretz/s-consts';

import type { HtzUser } from '@haaretz/s-types';

const items = {
  login: {
    name: 'login',
    title: 'התחברות',
    iconName: 'user',
    href: fork({
      default:
        'https://login.haaretz.co.il/?htm_source=site&htm_medium=navigation&htm_campaign=login&htm_content=login',
      tm: 'https://login.themarker.com/?htm_source=site&htm_medium=navigation&htm_campaign=login&htm_content=login',
      hdc: 'https://login.haaretz.com/?htm_source=site&htm_medium=navigation&htm_campaign=login&htm_content=login',
    }),
    hasMenu: false,
  },
  buySubscription: {
    name: 'purchase',
    title: 'רכישת מינוי',
    iconName: 'key',
    href: `https://promotion.${hostname}/offers?htm_source=site&htm_medium=button&htm_campaign=subscription&htm_campaign_type=subscription&htm_content=hello_default`,
    hasMenu: false,
  },
  buySubscriptioPartial: {
    name: 'purchase',
    title: 'שדרוג מינוי',
    iconName: 'key',
    href: `https://promotion.${hostname}/offers?htm_source=site&htm_medium=button&htm_campaign=subscription&htm_campaign_type=upsell
    subscription&htm_content=hello_default`,
    hasMenu: false,
  },
  podcast: {
    name: 'podcast',
    title: 'פודקאסטים',
    iconName: 'headphones',
    href: fork({
      default: 'https://www.haaretz.co.il/digital/podcast',
      tm: 'https://www.themarker.com/podcasts',
      hdc: 'https://www.haaretz.com/israel-news/podcasts',
    }),
    hasMenu: false,
  },
  weekly10: {
    name: 'weeklyTen',
    title: '10 הנצפות',
    iconName: 'ten',
    href: fork({
      default: 'https://www.haaretz.co.il/most',
      tm: 'https://www.themarker.com/10most',
    }),
    hasMenu: false,
  },
  finance: {
    name: 'finance',
    title: 'finance',
    iconName: 'finance',
    href: 'https://finance.themarker.com',
    hasMenu: false,
  },
  settings: {
    name: 'settings',
    title: 'הגדרות',
    iconName: 'user',
    href: null,
    hasMenu: true,
  },
  games: {
    name: 'games',
    title: 'משחקים',
    iconName: 'dice',
    href: 'https://www.haaretz.co.il/riddles',
    hasMenu: false,
  },
  menuBtn: {
    name: 'mobileNavigation',
    title: null,
    iconName: fork<'alef' | 'm'>({
      default: 'alef',
      tm: 'm',
    }),
    href: null,
    hasMenu: true,
  },
} as const;

const {
  login,
  buySubscription,
  podcast,
  weekly10,
  finance,
  settings,
  games,
  menuBtn,
  buySubscriptioPartial,
} = items;

const anonymous = fork({
  default: [login, buySubscription, menuBtn, weekly10, podcast],
  tm: [login, buySubscription, menuBtn, weekly10, finance],
});

const registered = fork({
  default: [settings, buySubscription, menuBtn, podcast, weekly10],
  tm: [settings, buySubscription, menuBtn, finance, weekly10],
});

const paying = fork({
  default: [settings, games, menuBtn, weekly10, podcast],
  tm: [settings, podcast, menuBtn, weekly10, finance],
});

const partialSubscriber = fork({
  default: [settings, buySubscriptioPartial, menuBtn, weekly10, podcast],
  tm: [settings, podcast, menuBtn, weekly10, finance],
});

// TODO seperate between href and non href
export default function getNavbarItem(
  userType: HtzUser['userType'],
  isPartialSubscriber: boolean,
  itemNumber: number
) {
  switch (userType) {
    case 'paying':
      if (isPartialSubscriber) return partialSubscriber[itemNumber];
      return paying[itemNumber];
    case 'registered':
      return registered[itemNumber];
    default:
      return anonymous[itemNumber];
  }
}

export function getNavBarItemsStr(userType: HtzUser['userType'], isPartialSubscriber: boolean) {
  let itemsArray: (typeof items)[keyof typeof items][] = [];
  switch (userType) {
    case 'paying':
      if (isPartialSubscriber) itemsArray = partialSubscriber;
      else itemsArray = paying;
      break;
    case 'registered':
      itemsArray = registered;
      break;
    case 'anonymous':
      itemsArray = anonymous;
      break;
  }

  const names: string[] = [];

  for (const item of itemsArray) {
    if (item.name !== 'mobileNavigation') {
      names.push(item.name);
    }
  }
  return names.toString();
}
