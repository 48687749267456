'use client';

import color from '@haaretz/l-color.macro';
import useMagazineCoverColor from '@haaretz/s-atoms/magazineCoverColor';
import MagazineLayout from '@haaretz/s-magazine-layout';
import s9 from 'style9';

const c = s9.create({
  layout: {
    '--mHTxtClr': color('textDarkAllModes'),
    color: 'var(--mHTxtClr)',
    position: 'relative',
    zIndex: 1,
  },
  onDark: {
    '--mHTxtClr': color('textLightAllModes'),
  },
  noCoverColor: {
    '--mHTxtClr': color('neutral1100'),
  },
});

export default function MagazineMasthead({ children }: { children: React.ReactNode }) {
  const coverColor = useMagazineCoverColor();
  const isOnDark = coverColor === 'black';

  return (
    <MagazineLayout
      as="header"
      styleExtend={[c.layout, !coverColor ? c.noCoverColor : isOnDark ? c.onDark : null]}
    >
      {children}
    </MagazineLayout>
  );
}
