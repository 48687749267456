import fork from '@haaretz/l-fork.macro';

const greetings = fork({
  default: {
    morning: 'בוקר טוב',
    noon: 'צהריים טובים',
    afternoon: 'אחה"צ טובים',
    evening: 'ערב טוב',
    night: 'לילה טוב',
    default: 'שלום',
  },
  hdc: {
    morning: 'Good Morning',
    noon: 'Good Day',
    afternoon: 'Good Day',
    evening: 'Good Evening',
    night: 'Good Night',
    default: 'Hi',
  },
});

function getGreetingAccordingTime() {
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 12) return greetings.morning;
  if (hour >= 12 && hour < 15) return greetings.noon;
  if (hour >= 15 && hour < 18) return greetings.afternoon;
  if (hour >= 18 && hour < 21) return greetings.evening;
  if ((hour >= 21 && hour < 24) || (hour >= 0 && hour < 5)) return greetings.night;
  return greetings.default;
}

export default function greetUser() {
  const greeting = getGreetingAccordingTime();
  return `${greeting}, `;
}
