'use client';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import usbBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

export interface SearchProps {
  children?: React.ReactNode;
  inlineStyle?: InlineStyles;
  styleExtend?: StyleExtend;
}

// `c` is short for `classNames`
const c = s9.create({
  icon: {
    fontSize: space(5),
    marginInlineStart: space(1),
  },
  txt: {
    fontWeight: 700,
    marginInlineEnd: space(0.5),
    ...typesetter(-2),
    ...merge(
      mq({
        from: 's',
        until: 'm',
        value: {
          display: 'none',
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
  btn: {
    color: 'var(--mHTxtClr)',
    paddingInlineStart: space(3),
    paddingInlineEnd: space(3),
    marginTop: space(0.25),

    ':hover': {
      '--mHTxtClr': color('neutral1100'),
    },
    ':focus': {
      '--mHTxtClr': color('neutral1100'),
    },
  },
});

export default function Search() {
  const biAction = usbBi();
  return (
    <Button
      variant="neutral"
      priority="tertiary"
      styleExtend={[c.btn]}
      contentStyleExtend={[c.txt]}
      href={'/ty-search'}
      as={HtzLink}
      data-testid="search-btn"
      onClick={() => {
        biAction({
          action_id: 143,
          feature: 'Search from Header',
          feature_type: 'Content',
        });
      }}
    >
      חיפוש
      <Icon icon="search" styleExtend={[c.icon]} />
    </Button>
  );
}
