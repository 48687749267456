export default function getBiDataFromMastheadItem(title: string, isCommercial: boolean) {
  let biData;

  if (isCommercial) {
    biData = {
      action_id: 3,
      feature_type: 'Marketing',
      feature: 'main navigation',
      campaign_name: 'main navigation',
      campaign_details: title,
    };
  } else {
    biData = {
      action_id: 153,
      feature_type: 'Content',
      feature: 'main navigation - sections',
      campaign_details: title,
    };
  }

  return biData;
}
